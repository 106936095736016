import Modal from "./modal";

/**
 * Обратная функция для TomSelect2 (отображает форму подтверждения добавления).
 */
export function createCallback(uri, params, idField = 'value', titleField = 'text', afterAddCallback = null) {
    return function (input, callback) {
        let p;

        if (typeof params === 'function') {
            p = params();
        } else {
            p = params;
        }

        const confirmModal = new Modal('Подтверждение', 'Действительно хотите добавить "' + input + '"?', {
            yesLabel: 'Добавить',
            callback: async function () {
                fetch(uri, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({input, params: p})
                })
                    .then(response => {
                        if (response.status !== 200) {
                            (new Modal('Ошибка!', response.statusText, {type: "alert"})).show();
                        } else {
                            return response.json()
                        }
                    })
                    .then(data => {
                        if (data.value > 0) {
                            callback({
                                [idField]: data.value,
                                [titleField]: data.text,
                            });

                            if (typeof afterAddCallback == 'function') {
                                afterAddCallback()
                            }
                        }
                    });
            }
        });

        confirmModal.show();
    }
}

/**
 * Стандартные настройки рендера для TomSelect2.
 */
export function getRenderDefaults() {
    return {
        option_create: function (data, escape) {
            return '<div class="create">Добавить <strong>' + escape(data.input) + '</strong>&hellip;</div>';
        },
        no_more_results: function (data, escape) {
            return '<div class="no-results">Результатов больше нет</div>';
        },
        no_results: function (data, escape) {
            return '<div class="no-more-results option">Нет результатов для "' + escape(data.input) + '"</div>';
        },
    }
}

/**
 * Стандартное поведение события onItemAdd для TomSelect2.
 */
export function onItemAddDefault() {
    return function () {
        this.setTextboxValue('');
    }
}

/**
 * Список плагинов по умолчанию для TomSelect2.
 */
export function getPluginsDefaults() {
    return {
        remove_button: {
            title: 'Удалить',
        },
        clear_button: {
            title: 'Очистить',
        },
        caret_position: {},
    }
}