/**
 * Обработчик содержимого выпадающих списков в ячейках.
 */
export function handleCellDropdowns() {
    $('body').on('DOMSubtreeModified', ".luckysheet-cell-input", function() {
        const search = $(this).text().toLowerCase();
        let contains = false;

        $('.dropdown-List-item').each(function() {
            $(this).css('display', 'block');

            const value = $(this).text().toLowerCase();

            if (!value.includes(search)) {
                $(this).css('display', 'none');
            } else {
                contains = true;
            }
        })

        if (!contains) {
            $('.dropdown-List-item').each(function() {
                $(this).css('display', 'block');
            });
        }
    });
}