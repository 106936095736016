import {Controller} from "@hotwired/stimulus";

/**
 * Обработчик формы смены Исполнителя брифа в административной панели.
 */
export default class extends Controller {
    static get targets() {
        return ['executorId', 'briefList'];
    }

    async getBriefs(event) {
        event.preventDefault();

        const id = this.executorIdTarget.value;

        let response = await fetch('/admin/executor-brief/briefs?id=' + id);

        if (response.status === 200) {
            const briefs = await response.json();

            if (briefs.length === 0) {
                this.briefListTarget.innerHTML = '<li>Нет назначенных брифов.</li>';
            } else {
                this.briefListTarget.innerHTML = '';

                briefs.forEach((brief) => {
                    this.briefListTarget.innerHTML += '<li><label for="">' + brief +
                        '</label></li><input type="hidden" value="' + brief + '" >';
                });
            }
        }
    }
}