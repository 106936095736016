import {Controller} from "@hotwired/stimulus";
import TomSelect from "tom-select";
import {getPluginsDefaults, getRenderDefaults, onItemAddDefault} from "../../../components/tomselect2";

/**
 * Обработчик формы поиска в разделе "Документы в Google Drive" административной панели.
 */
export default class extends Controller {
    select;

    static get targets() {
        return ['select'];
    }

    connect() {
        this.select = new TomSelect(this.selectTarget, {
            onChange: () => {
                this.onSelectChange(this.selectTarget);
            },
            render: getRenderDefaults(),
            onItemAdd: onItemAddDefault(),
            plugins: getPluginsDefaults(),
        });
    }

    disconnect() {
        this.select.destroy();
    }

    onSelectChange(select) {
        let selectedOption = select.options[select.selectedIndex];
        let searchWrapper = document.getElementById('search_wrapper');
        let searchLabel = searchWrapper.querySelector('label');
        let searchInput = document.getElementById('search_input');

        if (selectedOption.value === '1') {
            searchLabel.innerHTML = 'По названию файла';
            searchInput.name = 'search_google_file';
            searchWrapper.classList.remove('d-none');
        } else if (selectedOption.value === '2') {
            searchLabel.innerHTML = 'По ссылке на файл';
            searchInput.name = 'search_google_link';
            searchWrapper.classList.remove('d-none');
        } else {
            searchLabel.innerHTML = '';
            searchInput.name = '';
            searchWrapper.classList.add('d-none');
        }
    }
}