import {Controller} from "@hotwired/stimulus";

/**
 * Обработчик формы смены руководителя в административной панели.
 */
export default class extends Controller {
    static get targets() {
        return ['directorId', 'userList'];
    }

    async getUsers(event) {
        event.preventDefault();

        const id = this.directorIdTarget.value;

        let response = await fetch('/admin/user-director/users?id=' + id);

        if (response.status === 200) {
            const users = await response.json();

            if (users.length === 0) {
                this.userListTarget.innerHTML = '<li>Нет подчиненных.</li>';
            } else {
                this.userListTarget.innerHTML = '';

                users.forEach((user) => {
                    this.userListTarget.innerHTML += '<li><label for="">' + user +
                        '</label></li><input type="hidden" value="' + user + '" >';
                });
            }
        }
    }
}