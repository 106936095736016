/**
 * Конфигурация по умолчанию для контекстного меню Luckysheet.
 */
export const cellRightClickConfig ={
    copy: true,
    copyAs: false,
    paste: true,
    insertRow: false,
    insertColumn: false,
    deleteRow: false,
    deleteColumn: false,
    deleteCell: false,
    hideRow: false,
    hideColumn: false,
    rowHeight: false,
    columnWidth: false,
    clear: true,
    matrix: false,
    sort: false,
    filter: false,
    chart: false,
    image: false,
    link: false,
    data: false,
    cellFormat: false
};