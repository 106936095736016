import {Controller} from "@hotwired/stimulus";
import {handleCellDropdowns} from "../../../components/luckysheet/dropdownhandler";
import {cellRightClickConfig} from "../../../components/luckysheet/defaultconfig";

/**
 * Обработчик табличного редактора в разделе "Возврат площадки" административной панели.
 */
export default class extends Controller {
    luckysheet;

    static values = {
        data: String
    }

    static get targets() {
        return [
            'newDate',
            'table'
        ]
    }

    connect() {
        handleCellDropdowns();

        const options = {
            container: this.tableTarget.id,
            data: JSON.parse(this.dataValue),
            showtoolbar: false,
            showinfobar: false,
            showstatisticBar: false,
            sheetFormulaBar: false,
            lang: 'ru',
            showsheetbar: false,
            enableAddRow: false,
            cellRightClickConfig: cellRightClickConfig
        }

        luckysheet.create(options);

        this.luckysheet = luckysheet;
    }

    disconnect() {
        this.luckysheet.destroy();
    }

    async save() {
        const newDate = this.newDateTarget.value;
        const data = this.luckysheet.getAllSheets()[0]['data'];

        await fetch('', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                newDate: newDate,
                data: data
            })
        });

        window.location.reload();
    }
}